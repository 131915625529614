import styled from 'styled-components'
import { colors, media } from 'themes'

export const Wrapper = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 170px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px -4px #001d45;
  z-index: 2;
  color: ${colors.mainGrey};
  padding: 72px 16px 36px 16px;

  ${media.md`
    right: 15px;
  `}
`

export const Title = styled.div`
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  margin-bottom: 24px;
`

export const Ovals = styled.img`
  position: absolute;
  top: -48px;
  left: -48px;
`