import React, { Component } from 'react'
import Slider from 'react-slick'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import projects from 'config/projects'
import FileArrowDown from 'images/icon-arrowDown.svg'
import FileSliderOval from 'images/homeSlider-oval.svg'

import Container from 'components/shared/Container'
import { isMobile, isBigDesktop } from 'themes/utils'

import BuildingCard from './components/BuildingCard'
import {
  Wrapper,
  Slide,
  Progress,
  Title,
  Down,
  Image,
  Oval,
  Keywords,
} from './styled'

class Header extends Component {
  state = {
    percentTime: 0,
    tick: null,
  }

  time = 5
  settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    accessibility: false,
  }

  componentDidMount() {
    if (!isMobile) {
      this.startProgress()
    }
  }

  startProgress = () => {
    this.resetProgress()

    this.setState(
      {
        percentTime: 0,
      },
      () => {
        this.tick = setInterval(this.interval, 10)
      }
    )
  }

  resetProgress = () => {
    clearInterval(this.tick)
  }

  interval = () => {
    this.setState(
      previousState => ({
        percentTime: previousState.percentTime + 1 / (this.time + 0.1),
      }),
      () => {
        if (this.state.percentTime >= 100) {
          this.startProgress()
          this.slider.slickNext()
        }
      }
    )
  }

  render() {
    return (
      <Wrapper>
        <Container relative>
          <StaticQuery
            query={graphql`
              query {
                BarbizonHotel: allFile(
                  filter: {
                    relativeDirectory: { eq: "projects/BarbizonHotel/Slider" }
                  }
                ) {
                  edges {
                    node {
                      ...ImageFluid
                    }
                  }
                }

                Cuny: allFile(
                  filter: {
                    relativeDirectory: { eq: "projects/Cuny/Slider" }
                  }
                ) {
                  edges {
                    node {
                      ...ImageFluid
                    }
                  }
                }

                Highline: allFile(
                  filter: {
                    relativeDirectory: { eq: "projects/Highline/Slider" }
                  }
                ) {
                  edges {
                    node {
                      ...ImageFluid
                    }
                  }
                }

                Marshak: allFile(
                  filter: {
                    relativeDirectory: { eq: "projects/Marshak/Slider" }
                  }
                ) {
                  edges {
                    node {
                      ...ImageFluid
                    }
                  }
                }

                Medgar: allFile(
                  filter: {
                    relativeDirectory: { eq: "projects/Medgar/Slider" }
                  }
                ) {
                  edges {
                    node {
                      ...ImageFluid
                    }
                  }
                }

                MemorialSloan: allFile(
                  filter: {
                    relativeDirectory: { eq: "projects/MemorialSloan/Slider" }
                  }
                ) {
                  edges {
                    node {
                      ...ImageFluid
                    }
                  }
                }

                PacificPark: allFile(
                  filter: {
                    relativeDirectory: { eq: "projects/PacificPark/Slider" }
                  }
                ) {
                  edges {
                    node {
                      ...ImageFluid
                    }
                  }
                }

                RiversideCenter: allFile(
                  filter: {
                    relativeDirectory: { eq: "projects/RiversideCenter/Slider" }
                  }
                ) {
                  edges {
                    node {
                      ...ImageFluid
                    }
                  }
                }

                ThePlatinumCondo: allFile(
                  filter: {
                    relativeDirectory: { eq: "projects/ThePlatinumCondo/Slider" }
                  }
                ) {
                  edges {
                    node {
                      ...ImageFluid
                    }
                  }
                }

                Voorhees: allFile(
                  filter: {
                    relativeDirectory: { eq: "projects/Voorhees/Slider" }
                  }
                ) {
                  edges {
                    node {
                      ...ImageFluid
                    }
                  }
                }

                Moma: allFile(
                  filter: {
                    relativeDirectory: { eq: "projects/Moma/Slider" }
                  }
                ) {
                  edges {
                    node {
                      ...ImageFluid
                    }
                  }
                }

                PacificParkBuilding: allFile(
                  filter: {
                    relativeDirectory: { eq: "projects/PacificParkBuilding/Slider" }
                  }
                ) {
                  edges {
                    node {
                      ...ImageFluid
                    }
                  }
                }

                Normandy: allFile(
                  filter: {
                    relativeDirectory: { eq: "projects/Normandy/Slider" }
                  }
                ) {
                  edges {
                    node {
                      ...ImageFluid
                    }
                  }
                }

                OneWallStreet: allFile(
                  filter: {
                    relativeDirectory: { eq: "projects/OneWallStreet/Slider" }
                  }
                ) {
                  edges {
                    node {
                      ...ImageFluid
                    }
                  }
                }

                OneWestEnd: allFile(
                  filter: {
                    relativeDirectory: { eq: "projects/1WestEnd/Slider" }
                  }
                ) {
                  edges {
                    node {
                      ...ImageFluid
                    }
                  }
                }
              }
            `}
            render={data => {
              return (
                <div>
                  {!isMobile && (
                    <Slider
                      {...this.settings}
                      ref={slider => (this.slider = slider)}
                    >
                      {data &&
                        projects.map(item => {
                          const image = isBigDesktop
                            ? data[item.name].edges.find(
                                photo => photo.node.name === 'bigdesktop'
                              )
                            : data[item.name].edges.find(
                                photo => photo.node.name === 'desktop'
                              )
                          return (
                            <Slide key={item.name}>
                              <Oval src={FileSliderOval} />
                              <Image>
                                <Img
                                  fluid={image.node.childImageSharp.fluid}
                                  imgStyle={{
                                    left: 'auto',
                                    width: 'auto',
                                    height: '100%',
                                    right: 0,
                                    objectFit: 'contain',
                                  }}
                                />
                              </Image>
                              <BuildingCard to={`/portfolio#${item.name}`}>{item.title}</BuildingCard>
                            </Slide>
                          )
                        })}
                    </Slider>
                  )}
                </div>
              )
            }}
          />

          <Oval mobile src={FileSliderOval} />

          <Keywords>
            Safety.
            <br />
            Precision.
            <br />
            Efficiency.
            <br />
          </Keywords>
          <Title>Leading New York Façade contractor.</Title>

          {!isMobile && (
            <Progress
              radius={17}
              stroke={4}
              progress={this.state.percentTime}
            />
          )}

          <Down>
            <img src={FileArrowDown} alt="Down" />
          </Down>
        </Container>
      </Wrapper>
    )
  }
}

export const ImageFluid = graphql`
  fragment ImageFluid on File {
    name
    childImageSharp {
      fluid(quality: 100) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
`

export default Header
