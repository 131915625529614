import styled from 'styled-components'
import SectionTitle from 'components/shared/SectionTitle'
import Button from 'components/shared/Button'
import Slider from 'react-slick'
import { colors, media } from 'themes'

export const TitleBox = styled.div`
  position: absolute;
  top: 0;
  right: calc(50% - 100px);
  width: calc(50vw + 100px);
  background: #f7f7f7;
  height: 215px;
  z-index: -1;

  ${media.xl`
    right: calc(50% - 200px);
    width: calc(50vw + 200px);
  `}

  ${media.sm`
    right: 0;
    width: 100%;
    height: 150px;
  `}
`

export const Title = styled(SectionTitle)`
  padding-top: 130px;
  margin-bottom: 40px;

  ${media.sm`
    margin-bottom: 32px;
    padding-top: 80px;
  `}
`

export const DescriptionWrapper = styled.div`
  background: ${colors.treeBrown};
  padding: 56px 0;
  color: white;
`

export const WorkTitle = styled.h3`
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;

  ${media.lg`
    padding-right: 35px;
  `}

  ${media.sm`
    padding-right: 0;
    margin-bottom: 20px;
  `}
`
export const BigImage = styled.div`
  position: absolute;
  top: calc(-56px - 86px);
  left: 0;
  z-index: 2;
  width: 100%;

  ${({ mobile }) => mobile && `
    display: none;
  `}

  &:hover {
    cursor: pointer;
  }

  ${media.xl`
    left: auto;
    right: 0;
    width: 556px;
    top: calc(-56px - 135px);
  `}

  ${media.md`
    width: 120%;
  `}

  ${media.sm`
    display: none;

    ${({ mobile }) => mobile && `
      display: block;
      position: relative;
      top: auto;
      bottom: auto;
      left: auto;
      right: auto;
      margin-bottom: 40px;
      max-height: 300px;
      overflow: hidden;
    `}
  `}
`

export const SmallImage = styled.div`
  position: absolute;
  top: 350px;
  left: -100px;
  width: 270px;
  height: auto;
  z-index: 3;

  &:hover {
    cursor: pointer;
  }

  ${media.xl`
    width: 346px;
    top: 255px;
  `}

  ${media.lg`
    top: 330px;
  `}

  ${media.md`
    top: 250px;
    left: -50px;
  `}

  ${media.sm`
    display: none;
  `}
`

export const WorkSlider = styled(Slider)`
  && {
    ${media.sm`
      overflow: hidden;
    `}

    .slick-list {
      overflow: visible;

      .slick-slide {
        pointer-events: none;

        &.slick-current {
          pointer-events: auto;
        }
      }
    }
  }
`

export const ShowGallery = styled(Button)`
  margin-top: 16px;
`

export const WorkDescription = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 22px;
  margin-top: 14px;
  margin-bottom: 32px;
  opacity: 0.5;

  ${media.sm`
    display: none;
  `}
`

export const HideMobile = styled.div`
  display: block;

  ${media.sm`
    display: none;
  `}
`