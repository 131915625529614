import React from 'react'
import { Row, Col } from 'styled-bootstrap-grid'

import SectionTitle from 'components/shared/SectionTitle'
import Container from 'components/shared/Container'

import { Wrapper, More, Number, Service, MainDescription } from './styled'

const Offer = () => (
  <Wrapper>
    <Container>
      <SectionTitle>What we offer</SectionTitle>

      <Row>
        <Col lg={6}>
          <MainDescription>
            Building close relationships with our customers is an integral part
            of our process. Through strong collaboration, dedication, and
            transparency – we design ambitious solutions to meet the highest
            standards.
          </MainDescription>
        </Col>
      </Row>
      <Row>
        <Col md={4} lg={3}>
          <Number>01</Number>
          <Service>Façade new construction and replacement</Service>
        </Col>
        <Col md={4} lg={3} lgOffset={1}>
          <Number>02</Number>
          <Service>Curtain walls, Window walls</Service>
        </Col>
        <Col md={4} lg={3} lgOffset={1}>
          <Number>03</Number>
          <Service>Ornamental and metal works</Service>
        </Col>
      </Row>

      <More type="text" to="/services">
        More about services
      </More>
    </Container>
  </Wrapper>
)

export default Offer
