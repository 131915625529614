import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/shared/Button'
import FileOvals from 'images/homeSlider-circles.svg';

import { Wrapper, Title, Ovals } from './styled';

const BuildingCard = ({ to, children }) => (
  <Wrapper>
    <Ovals src={FileOvals} />
    <Title>{children}</Title>
    <Button type="text" to={to}>More details</Button>
  </Wrapper>
);

BuildingCard.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
}

export default BuildingCard;
