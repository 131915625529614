import styled from 'styled-components'
import { media } from 'themes'

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: calc(50% - 35px);
  display: flex;
  width: 70px;
  background-color: #ffb400;
  z-index: 5;

  ${media.xl`
    left: calc(50% + 75px);
    top: 60px;
  `}

  ${media.md`
    left: calc(58% - 35px);
  `}

  ${media.sm`
    left: auto;
    right: 15px;
    top: -73px;
  `}
`

export const Arrow = styled.a`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;

  &:hover {
    cursor: pointer;
  }
`
