import styled from 'styled-components'
import Button from 'components/shared/Button'
import Description from 'components/shared/Description'
import { colors, media } from 'themes'

export const Wrapper = styled.section`
  padding-top: 150px;
  padding-bottom: 112px;

  ${media.sm`
    padding-top: 80px;
  `}
`

export const More = styled(Button)`
  margin-top: 88px;
`

export const Number = styled.div`
  color: ${colors.mutedWhite};
  font-size: 48px;
  font-weight: 400;
  line-height: 58px;
  margin: 64px 0 32px;
`

export const Service = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
`

export const MainDescription = styled(Description)`
  ${media.md`
    padding-right: 100px;
  `}
`