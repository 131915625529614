import React, { Component } from "react";
import { Row, Col } from "styled-bootstrap-grid";
import Img from "gatsby-image";

import Info from "../Info";
import {
  WorkTitle,
  BigImage,
  SmallImage,
  ShowGallery,
  WorkDescription,
  HideMobile,
} from "../../styled";

class Slide extends Component {
  render() {
    const {
      title,
      location,
      owner,
      cm,
      completion,
      architect,
      openLightbox,
      images,
      gallery,
      description,
    } = this.props;

    const bigImage = images.find((image) => image.node.name === "big").node
      .childImageSharp.fluid;

    const smallImage = images.find((image) => image.node.name === "small")?.node
      .childImageSharp.fluid;

    return (
      <Row>
        <Col md={7} lg={6}>
          <Row>
            <BigImage mobile onClick={(event) => openLightbox(event, gallery)}>
              <Img fluid={bigImage} />
            </BigImage>
            <Col lg={12}>
              <WorkTitle>{title}</WorkTitle>
              <WorkDescription
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </Col>
            <Col lg={6}>
              <Info label="Location" value={location} />

              <HideMobile>
                <Info label="Owner/Developer" value={owner} />
                <Info label="CM" value={cm} />
              </HideMobile>
            </Col>
            <Col lg={6}>
              <HideMobile>
                <Info label="Completion" value={completion} />
                <Info label="Architect" value={architect} />
              </HideMobile>
            </Col>
            <Col lg={12}>
              <ShowGallery
                to="/"
                type="text"
                onClick={(event) => openLightbox(event, gallery)}
              >
                Show gallery
              </ShowGallery>
            </Col>
          </Row>
        </Col>
        <Col md={5} lg={5} hiddenSmDown>
          <BigImage onClick={(event) => openLightbox(event, gallery)}>
            <Img fluid={bigImage} />
          </BigImage>

          {smallImage && (
            <SmallImage onClick={(event) => openLightbox(event, gallery)}>
              <Img fluid={smallImage} />
            </SmallImage>
          )}
        </Col>
      </Row>
    );
  }
}

export default Slide;
