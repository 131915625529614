import React from 'react'
import { Row, Col } from 'styled-bootstrap-grid'

import FileIconHandshake from 'images/icon-handshake.svg';
import FileIconPin from 'images/icon-pin.svg';
import FileIconShield from 'images/icon-shield.svg';

import SectionTitle from 'components/shared/SectionTitle'
import Description from 'components/shared/Description'
import Container from 'components/shared/Container'

import { Icon, Info, Wrapper, LearnMore, Tile } from './styled'

const AboutUs = () => (
  <Wrapper>
    <Container>
      <SectionTitle>About us</SectionTitle>

      <Row>
        <Col lg={5}>
          <Description>
            Metro-Tech Erectors Corp. is a respected market leader in commercial
            and industrial window installation based in the New York Tri-State
            area.
          </Description>

          <LearnMore type="text" to="/about">Learn more</LearnMore>
        </Col>
        <Tile md={4} lg={2} lgOffset={1}>
          <Icon src={FileIconHandshake} />
          <Info>Founded in 2004</Info>
        </Tile>
        <Tile md={4} lg={2}>
          <Icon src={FileIconPin} />
          <Info>Based in the New&nbsp;York Tri&#8209;State area</Info>
        </Tile>
        <Tile md={4} lg={2}>
          <Icon src={FileIconShield} />
          <Info>Fully insured</Info>
        </Tile>
      </Row>
    </Container>
  </Wrapper>
)

export default AboutUs
