import React, { Component } from "react";
import { graphql } from "gatsby";
import Lightbox from "react-images";

import projects from "config/projects";
import Container from "components/shared/Container";

import Arrows from "./components/Arrows";
import Slide from "./components/Slide";

import { TitleBox, Title, DescriptionWrapper, WorkSlider } from "./styled";

const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  arrows: false,
  accessibility: false,
};

class OurWork extends Component {
  state = {
    lightboxIsOpen: false,
    currentImage: 0,
    gallery: [],
  };

  goPrev = () => {
    this.slider.slickPrev();
  };

  goNext = () => {
    this.slider.slickNext();
  };

  openLightbox = (event, gallery) => {
    event.preventDefault();

    this.setState({
      lightboxIsOpen: true,
      gallery,
    });
  };

  closeLightbox = () => {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
      gallery: [],
    });
  };

  gotoPrevious = () => {
    this.setState({
      currentImage: this.state.currentImage - 1,
    });
  };

  gotoNext = () => {
    this.setState({
      currentImage: this.state.currentImage + 1,
    });
  };

  gotoImage = (index) => {
    this.setState({
      currentImage: index,
    });
  };

  render() {
    const { data } = this.props;

    return (
      <div>
        <Container relative>
          <Title>Our work</Title>
          <TitleBox />
        </Container>
        <DescriptionWrapper>
          <Container relative>
            <Arrows goPrev={this.goPrev} goNext={this.goNext} />

            <WorkSlider
              {...sliderSettings}
              ref={(slider) => (this.slider = slider)}
            >
              {projects.map((item) => (
                <Slide
                  key={item.name}
                  name={item.name}
                  title={item.title}
                  location={item.location}
                  owner={item.owner}
                  description={item.description}
                  cm={item.cm}
                  completion={item.completion}
                  architect={item.architect}
                  images={data[`${item.name}_OurWork`].edges}
                  gallery={data[`${item.name}_Gallery`].edges}
                  openLightbox={this.openLightbox}
                />
              ))}
            </WorkSlider>
          </Container>
        </DescriptionWrapper>

        {this.state.gallery.length > 0 && (
          <Lightbox
            images={this.state.gallery.map((photo) => ({
              src: photo && photo.node.childImageSharp.fluid.src,
            }))}
            isOpen={this.state.lightboxIsOpen}
            currentImage={this.state.currentImage}
            onClickNext={this.gotoNext}
            onClickPrev={this.gotoPrevious}
            onClose={this.closeLightbox}
            onClickThumbnail={this.gotoImage}
            backdropClosesModal
            width={2000}
          />
        )}
      </div>
    );
  }
}

export const Image = graphql`
  fragment Image on File {
    name
    childImageSharp {
      fluid(quality: 100) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
`;

export default OurWork;
