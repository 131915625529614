import React from 'react'
import { Wrapper, Label, Value } from './styled';

const Info = ({ label, value }) => (
  <Wrapper>
    <Label>{label}:</Label>
    <Value>{value}</Value>
  </Wrapper>
)

export default Info;
