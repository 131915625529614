import React from 'react'
import FileArrowLeft from 'images/arrowLeft.svg';
import FileArrowRight from 'images/arrowRight.svg';
import { Wrapper, Arrow } from './styled';

const Arrows = ({ goPrev, goNext }) => (
  <Wrapper>
    <Arrow onClick={() => goPrev()}>
      <img src={FileArrowLeft} alt="Previous" />
    </Arrow>
    <Arrow onClick={() => goNext()}>
      <img src={FileArrowRight} alt="Next" />
    </Arrow>
  </Wrapper>
)

export default Arrows;
