import styled from 'styled-components'

export const Wrapper = styled.div`
  margin-bottom: 16px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 32px;
  font-weight: 500;
`

export const Label = styled.div`
  opacity: 0.5;
`

export const Value = styled.div`
  font-weight: 800;
`
