import styled from 'styled-components'
import { colors } from 'themes'

const Description = styled.h3`
  font-family: 'Roboto', sans-serif;
  color: ${colors.lighterGray};
  font-size: 16px;
  line-height: 32px;
`

export default Description
