import styled from 'styled-components'
import ProgressRing from './components/ProgressRing'
import { colors, media } from 'themes'

const WithBlueTransparentOverlay = `
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(29, 74, 137, 0) 0%, #1d4a89 100%);
    z-index: 1;
  }
`;

export const Wrapper = styled.section`
  position: relative;
  background: ${colors.mainBlue};
  color: white;

  ${media.sm`
    padding-bottom: 100px;
    overflow: hidden;
  `}
`

export const Slide = styled.div`
  position: relative;
  width: 100%;
  height: 604px;

  ${WithBlueTransparentOverlay}
`

export const Progress = styled(ProgressRing)`
  && {
    position: absolute;
    bottom: 16px;
    left: 8px;
    z-index: 1;
  }
`

export const Title = styled.h2`
  position: absolute;
  bottom: 140px;
  left: 15px;
  font-weight: 300;
  font-size: 72px;
  line-height: 87px;
  max-width: 670px;
  z-index: 1;

  ${media.md`
    max-width: 370px;
    font-size: 48px;
    line-height: 58px;
  `}

  ${media.sm`
    position: relative;
    top: auto;
    left: auto;
    bottom: auto;
    right: auto;
    z-index: 5;
  `}
`

export const Down = styled.div`
  position: absolute;
  right: 200px;
  z-index: 1;
  bottom: -360px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 70px;
  height: 430px;
  padding-top: 22px;
  background: linear-gradient(0deg, rgba(0, 168, 229, 0) 0%, #00a8e5 100%);

  ${media.xl`
    right: 220px;
  `}

  ${media.md`
    right: 15px;
  `}

  ${media.sm`
    right: auto;
    left: 15px;
    bottom: -140px;
    height: 110px;
  `}
`

export const Image = styled.div`
  position: absolute;
  top: 0;
  right: 100px;
  height: 100%;
  width: calc(100% - 100px);

  .gatsby-image-wrapper > div {
    padding-bottom: 0 !important;
    height: 604px;
  }

  ${media.md`
    width: 100%;
    right: 0;
  `}
`

export const Oval = styled.img`
  position: absolute;
  top: 45px;
  left: 100px;
  z-index: 1;

  ${({ mobile }) => mobile && `
    display: none;
  `}
    
  ${media.sm`
    ${({ mobile }) => mobile && `
      display: block;
      top: -15px;
      left: -270px;
    `}
  `}
`

export const Keywords = styled.div`
  position: absolute;
  top: 140px;
  left: 170px;
  z-index: 1;
  color: ${colors.brightBlue};
  font-size: 30px;
  font-weight: 500;
  line-height: 36px;
  text-align: right;

  ${media.md`
    left: 15px;
    text-align: left;
    top: 77px;
  `}

  ${media.sm`
    position: static;
    top: auto;
    left: auto;
    padding-top: 53px;
    margin-bottom: 30px;
  `}
`
