import React from 'react'
import { graphql } from 'gatsby'

import Layout from 'components/shared/Layout'
import Header from 'components/Home/Header'
import Offer from 'components/Home/Offer'
import OurWork from 'components/Home/OurWork'
import AboutUs from 'components/Home/AboutUs'
import ContactLink from 'components/shared/ContactLink'


const IndexPage = ({ data }) => (
  <Layout>
    <Header />
    <Offer />
    <OurWork data={data} />
    <AboutUs />
    <ContactLink />
  </Layout>
);

export const query = graphql`
  query Images {
    BarbizonHotel_OurWork: allFile(filter: {
      relativeDirectory: { eq: "projects/BarbizonHotel/OurWork" }
    }) {
      edges {
        node {
          ...Image
        }
      }
    }

    BarbizonHotel_Gallery: allFile(filter: {
      relativeDirectory: { eq: "projects/BarbizonHotel/Portfolio" }
    }) {
      edges {
        node {
          ...Image
        }
      }
    }

    Cuny_OurWork: allFile(filter: {
      relativeDirectory: { eq: "projects/Cuny/OurWork" }
    }) {
      edges {
        node {
          ...Image
        }
      }
    }

    Cuny_Gallery: allFile(filter: {
      relativeDirectory: { eq: "projects/Cuny/Portfolio" }
    }) {
      edges {
        node {
          ...Image
        }
      }
    }

    Highline_OurWork: allFile(filter: {
      relativeDirectory: { eq: "projects/Highline/OurWork" }
    }) {
      edges {
        node {
          ...Image
        }
      }
    }

    Highline_Gallery: allFile(filter: {
      relativeDirectory: { eq: "projects/Highline/Portfolio" }
    }) {
      edges {
        node {
          ...Image
        }
      }
    }

    Marshak_OurWork: allFile(filter: {
      relativeDirectory: { eq: "projects/Marshak/OurWork" }
    }) {
      edges {
        node {
          ...Image
        }
      }
    }

    Marshak_Gallery: allFile(filter: {
      relativeDirectory: { eq: "projects/Marshak/Portfolio" }
    }) {
      edges {
        node {
          ...Image
        }
      }
    }

    Medgar_OurWork: allFile(filter: {
      relativeDirectory: { eq: "projects/Medgar/OurWork" }
    }) {
      edges {
        node {
          ...Image
        }
      }
    }

    Medgar_Gallery: allFile(filter: {
      relativeDirectory: { eq: "projects/Medgar/Portfolio" }
    }) {
      edges {
        node {
          ...Image
        }
      }
    }

    MemorialSloan_OurWork: allFile(filter: {
      relativeDirectory: { eq: "projects/MemorialSloan/OurWork" }
    }) {
      edges {
        node {
          ...Image
        }
      }
    }

    MemorialSloan_Gallery: allFile(filter: {
      relativeDirectory: { eq: "projects/MemorialSloan/Portfolio" }
    }) {
      edges {
        node {
          ...Image
        }
      }
    }

    PacificPark_OurWork: allFile(filter: {
      relativeDirectory: { eq: "projects/PacificPark/OurWork" }
    }) {
      edges {
        node {
          ...Image
        }
      }
    }

    PacificPark_Gallery: allFile(filter: {
      relativeDirectory: { eq: "projects/PacificPark/Portfolio" }
    }) {
      edges {
        node {
          ...Image
        }
      }
    }

    RiversideCenter_OurWork: allFile(filter: {
      relativeDirectory: { eq: "projects/RiversideCenter/OurWork" }
    }) {
      edges {
        node {
          ...Image
        }
      }
    }

    RiversideCenter_Gallery: allFile(filter: {
      relativeDirectory: { eq: "projects/RiversideCenter/Portfolio" }
    }) {
      edges {
        node {
          ...Image
        }
      }
    }

    ThePlatinumCondo_OurWork: allFile(filter: {
      relativeDirectory: { eq: "projects/ThePlatinumCondo/OurWork" }
    }) {
      edges {
        node {
          ...Image
        }
      }
    }

    ThePlatinumCondo_Gallery: allFile(filter: {
      relativeDirectory: { eq: "projects/ThePlatinumCondo/Portfolio" }
    }) {
      edges {
        node {
          ...Image
        }
      }
    }

    Voorhees_OurWork: allFile(filter: {
      relativeDirectory: { eq: "projects/Voorhees/OurWork" }
    }) {
      edges {
        node {
          ...Image
        }
      }
    }

    Voorhees_Gallery: allFile(filter: {
      relativeDirectory: { eq: "projects/Voorhees/Portfolio" }
    }) {
      edges {
        node {
          ...Image
        }
      }
    }

    Moma_OurWork: allFile(filter: {
      relativeDirectory: { eq: "projects/Moma/OurWork" }
    }) {
      edges {
        node {
          ...Image
        }
      }
    }

    Moma_Gallery: allFile(filter: {
      relativeDirectory: { eq: "projects/Moma/Portfolio" }
    }) {
      edges {
        node {
          ...Image
        }
      }
    }

    PacificParkBuilding_OurWork: allFile(filter: {
      relativeDirectory: { eq: "projects/PacificParkBuilding/OurWork" }
    }) {
      edges {
        node {
          ...Image
        }
      }
    }

    PacificParkBuilding_Gallery: allFile(filter: {
      relativeDirectory: { eq: "projects/PacificParkBuilding/Portfolio" }
    }) {
      edges {
        node {
          ...Image
        }
      }
    }

    Normandy_OurWork: allFile(filter: {
      relativeDirectory: { eq: "projects/Normandy/OurWork" }
    }) {
      edges {
        node {
          ...Image
        }
      }
    }

    Normandy_Gallery: allFile(filter: {
      relativeDirectory: { eq: "projects/Normandy/Portfolio" }
    }) {
      edges {
        node {
          ...Image
        }
      }
    }

    OneWallStreet_OurWork: allFile(filter: {
      relativeDirectory: { eq: "projects/OneWallStreet/OurWork" }
    }) {
      edges {
        node {
          ...Image
        }
      }
    }

    OneWallStreet_Gallery: allFile(filter: {
      relativeDirectory: { eq: "projects/OneWallStreet/Portfolio" }
    }) {
      edges {
        node {
          ...Image
        }
      }
    }

    OneWestEnd_OurWork: allFile(filter: {
      relativeDirectory: { eq: "projects/1WestEnd/OurWork" }
    }) {
      edges {
        node {
          ...Image
        }
      }
    }

    OneWestEnd_Gallery: allFile(filter: {
      relativeDirectory: { eq: "projects/1WestEnd/Portfolio" }
    }) {
      edges {
        node {
          ...Image
        }
      }
    }
  }
`

export default IndexPage
