import styled from 'styled-components'
import { Col } from 'styled-bootstrap-grid'
import Button from 'components/shared/Button'
import { media } from 'themes';

export const Wrapper = styled.section`
  padding-top: 293px;
  padding-bottom: 184px;

  ${media.md`
    padding-top: 150px;
  `}

  ${media.sm`
    padding: 80px 0;
  `}
`

export const Icon = styled.img`
  display: inline-block;
  margin-bottom: 25px;
`

export const Info = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
`

export const LearnMore = styled(Button)`
  margin-top: 88px;

  ${media.md`
    margin: 64px 0;
  `}
`;

export const Tile = styled(Col)`
  ${media.sm`
    margin-bottom: 56px;
  `}
`